.social path {
	color: var(--social-icon-color);
	box-shadow: var(--social-box-shadow);
	stroke: var(--text-color);
	stroke-width: 8px;
	text-shadow: 0 0 30px var(--text-color);
	transition: 0.35s;
}

.social {
	--social-icon-color: var(--text-color);
}

.social:hover,
.social:focus {
  --social-box-shadow: inset 6.5em 0 0 0 var(--background-color);
	--social-icon-color: var(--background-color);
}