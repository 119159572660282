.navbar {
	background-color: var(--background-color);
	color: var(--text-color);
	transition: var(--dark-mode-transition);
	z-index: 10;
}

.mobile-menu {
	background-color: var(--background-color);
	color: var(--text-color);
	transition: var(--dark-mode-transition);
}