@keyframes shrink {
	from {
		transform: scale(1.3);
	}
	to {
		transform: scale(1);
	}
}

.project-title {
	--title-font-size: 1.5rem;
  color: #eaeaea;
	font-size: var(--title-font-size);
	position: absolute;
	right: 0;
  left: 0;
	top: calc(50% - var(--title-font-size));
  margin: auto;
}

@keyframes fade-out {
  from {
		opacity: 100;
  }

  to {
		opacity: 0;
  }
}

@keyframes fade-in {
  from {
		opacity: 0;
  }

  to {
		opacity: 100;
  }
}

.project-card {
	cursor: pointer;
	overflow: hidden;
	position: relative;
}

.project-card:hover .project-title {
	animation: shrink 400ms;
	animation-fill-mode: forwards;
}

.project-preview-background {
	width: 100%;
	height: 100%;
	position: relative;
	top: -100%;
  background-position: center;
	background-size: cover;
	transition: opacity 400ms ease-in-out,transform 400ms ease-in-out;
}

.project-preview-background:hover {
	transform: scale(1.2);
	animation-duration: 500ms;
  animation-name: fade-out;
	animation-fill-mode: forwards;
}

.project-background {
	width: 100%;
	height: 100%;
  background-position: center;
	background-size: cover;
	background-blend-mode: overlay;
}