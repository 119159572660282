/* 
Font imports:
	Roboto (300, 400, 500, 700)
	Quicksand (300, 400, 500, 600, 700)
	Inter (100, 200, 300, 400, 500, 600, 700, 800, 900) 
*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;700&display=swap');

/* Material Icons import */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@tailwind base;
@tailwind components;
@tailwind utilities;
