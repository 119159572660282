.btn {
	background: var(--text-color);
	border-radius: 12px;
	border: 6px solid var(--text-color);
	color: var(--background-color);
	font-family: "Inter", sans-serif;
	padding: 12px;
	transition: 0.25s;
}

.btn:hover,
.btn:focus {
  box-shadow: inset 6.5em 0 0 0 var(--background-color);
	color: var(--text-color);
}