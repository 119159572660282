:root {
	--background-color:#eaeaea;
	--text-color: #222;
	--hover-color: #aeaeae;
	--tw-shadow-color: #000;
	--dark-mode-transition: background-color 1.5s, color 1.5s
}

[data-theme="dark"] {
	--background-color: #222;
	--text-color: #eaeaea;
	--hover-color: #444;
	--tw-shadow-color: #fff;
}

[data-theme="dark"][data-theme-initial="true"] {
	--dark-mode-transition: none;
}

body {
	background-color: var(--background-color);
	color: var(--text-color);
	font-family: "Rubik", sans-serif;
	transition: var(--dark-mode-transition);
}

svg path {
	fill: currentColor;
}

.no-scroll { 
	overflow: hidden;
}

a:active {
	color: var(--text-color);
}

main {
	margin-top: 6rem;
}

.rsis-container > div {
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: contain !important;
}

.blur-filter {
	filter: blur(1.75px);
}