.gradient-text {
	background: #6312C7;  /* fallback for old browsers */
	background: -webkit-linear-gradient(135deg, #6312C7, #893ee6, #5ea7f0, #5496d8);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(135deg, #6312C7, #893ee6, #5ea7f0, #5496d8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.headerAnchor {
	display: block;
	position: relative;
	top: -5rem;
	visibility: hidden;
}

